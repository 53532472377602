import { headerBreak } from '@/components/content/Header/styles/break';
import { SxProps, Theme } from '@mui/material';

export const utilityNavSX: SxProps<Theme> = (theme) => ({
	backgroundColor: 'primary.main',
	color: 'primary.contrastText',
	padding: '8px',
	[theme.breakpoints.up('md')]: {
		padding: '8px 58px',
	},
	'.MuiContainer-root': {
		padding: '0',
	},
	ul: {
		padding: 0,
		margin: 0,
	},
	li: {
        fontSize: '.75rem',
		listStyleType: 'none',
		':after': {
			content: "'|'",
			position: 'relative',
			left: '7px',
		},
	},
	a: {
		display: headerBreak({ mobile: 'none', desktop: 'inline' }),
		color: '#fff',
		'&:hover': {
			color: '#fff',
		},
	},
	button: {
		color: '#fff',
		fontSize: '.75rem',
		padding: 0,
		fontWeight: 400,
		'&:hover': {
			color: '#fff',
			textDecoration: 'underline',
		},
        '&.MuiButton-sizeSmall': {
            fontSize: '.75rem',
        },
	},
	'#destop-logo-wrapper': {
		display: 'flex',
	},
	'#mobile-logo-wrapper': {
		display: 'none'
	},
	[theme.breakpoints.down('md')]: {
		'#mobile-logo-wrapper': {
			display: 'flex',
			justifyContent: 'center',
		},
		'#destop-logo-wrapper': {
			display: 'none',
		},
	},
	[theme.breakpoints.down('sm')]: {
		'#mobile-logo-wrapper': {
			justifyContent: 'flex-start',
			'>span': {
				fontSize: '10px',
			},
		},
	},
});
