/* eslint-disable react/jsx-max-depth */
/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { HeaderNavBarExtended } from '@/components/content/Header/parts/NavBarExtended';
import { headerContainerUpdatedSX } from '@/components/content/Header/styles/container';
import { backDropSX } from '@/components/content/Header/styles/navBar/backDrop';
import {
	Container,
	Stack,
	useMediaQuery,
	useTheme,
	Typography,
	Box,
	Button,
	Modal,
	IconButton,
} from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { ID } from '@/data/types/Basic';
import { HeaderMobileToggleButton } from '@/components/content/Header/parts/MobileToggleButton';
import { HeaderMobileDrawer } from '@/components/content/Header/parts/MobileDrawer';
import { HeaderSearch } from '@/components/content/Header/parts/Search';
import { HeaderMiniCart } from '@/components/content/Header/parts/MiniCart';
import { HeaderAccountCustom } from '@/components/content/Header/parts/AccountCustom';
import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { UtilityNav } from '@/components/content/Header/parts/UtilityNav';
import { getChildItem } from '@/utils/getChildItem';
import { useHeader } from '@/data/Content/Header';
import { SkipToLink } from '@/components/content/Header/parts/SkipToLink';
import { HEADER_LOGO, HEADER_LOGO_PUNCHOUT, HEADER_MOBILE_NAV, HEADER_GLOBAL_BANNER } from '@/data/constants/marketing';
import { AdvantageLogo } from '@/components/content/Header/parts/AdvantageLogo';
import { AdvantageSubMenu } from '@/components/content/Header/parts/AdvantageSubMenu';
// import { Linkable } from '@/components/blocks/Linkable';
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faArrowRightFromBracket } from '@fortawesome/sharp-light-svg-icons';
import { useLocalization } from '@/data/Localization';
import { useJJKUser } from '@/data/User';
import { useESpotDataFromName } from '@/data/Content/_ESpotDataFromName';
import parse from 'html-react-parser';
import { HeaderGlobalBanner } from '@/components/content/Header/parts/HeaderGlobalBanner';

type Props = {
	id: ID;
};

export const Header: FC<Props> = ({ id }) => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const toggleDrawer = useCallback(
		(open?: boolean) => () => {
			setDrawerOpen(open !== undefined ? open : (current) => !current);
		},
		[]
	);
	const [bgDropState, setBgDropState] = useState<boolean>(false);

	const { contentItems } = useHeader(id);
	const { contentItem: headerLogo } = getChildItem(contentItems, HEADER_LOGO);
	const { contentItem: mobileNavEspotData } = getChildItem(contentItems, HEADER_MOBILE_NAV);
	const { contentItem: headerGlobalBanner } = getChildItem(contentItems, HEADER_GLOBAL_BANNER);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const punchOutUser = useJJKUser();
	const isPunchOut = punchOutUser?.user?.isPunchoutUser;

	return (
		<>
			{
				isPunchOut ?
					(<PunchOutHeader isMobile={isMobile} />) :
					(
						<>
							<Box id="header" component="header" sx={headerContainerUpdatedSX({isMobile})}>

								<UtilityNav id={id} headerLogo={headerLogo} />
								<Box id="header__primary" sx={{ width: '100%', position: 'relative', zIndex: 1, backgroundColor: '#fff' }}>
									<Container maxWidth="xl">
										<Stack
											direction="row"
											alignItems="center"
											justifyContent="space-between"
											spacing={isMobile ? 0 : 2}
											py={2}
											gap={{'xs': '0 19px', 'md': '0'}}
										>
											<Stack
												sx={{ display: { xs: 'flex', md: 'none' } }}
												direction="row"
												alignItems="center"
												spacing={2}
											>
												<HeaderMobileToggleButton toggleDrawer={toggleDrawer} open={drawerOpen} />
												<HeaderMobileDrawer
													mobileNavEspotData={mobileNavEspotData}
													toggleDrawer={toggleDrawer}
													open={drawerOpen}
												/>
											</Stack>
											<Stack id="header__logo" sx={{ maxWidth: { xs: 144, md: 175 }, display: { xs: 'none', md: 'flex' } }} direction="column" alignItems="center">
												<ContentRecommendation id={`${id}-${headerLogo?.emsName}`} properties={headerLogo} />
											</Stack>
											<Stack
                                                id="header__search"
												direction="row"
												alignItems="center"
												spacing={2}
												sx={{ display: { xs: 'flex', md: 'block' }, width: {'xs': '100%', sm: 'inherit'}, marginLeft: {'xs': '0', 'md': 'inherit'} }}
											>
												<HeaderSearch mobile={isMobile} />
											</Stack>
											<Stack
                                                id="header__utility-navigation"
												direction="row"
												spacing={isMobile ? 1 : 3}
												alignItems="center"
												justifyContent="flex-end"
												sx={{marginLeft: {'xs': '0 !important'}}}
											>
												<HeaderAccountCustom id={id} mobileBreakpoint="md" />
												<AdvantageLogo />
												<HeaderMiniCart mobileBreakpoint="md" />
											</Stack>
										</Stack>
									</Container>
								</Box>
								<SkipToLink />
								<HeaderNavBarExtended setBgDropState={setBgDropState} />
							</Box >
							<AdvantageSubMenu />
							<Box>
								<HeaderGlobalBanner id={`${id}-${headerGlobalBanner?.emsName}`} headerGlobalBanner={headerGlobalBanner} />
							</Box>
							<Box sx={backDropSX({ bgDropState })} />
						</>
					)
			}
		</>

	);
};


const PunchOutHeader: FC<{isMobile: boolean}> = ({isMobile}) => {
	const [open, setOpen] = useState(false);
	const PunchOutLabels = useLocalization('PunchOutHeader');
	const { data } = useESpotDataFromName(HEADER_LOGO_PUNCHOUT);
	const logoData: string | undefined = data?.MarketingSpotData?.[0]?.baseMarketingSpotActivityData?.[0]?.marketingContentDescription?.[0]?.marketingText;

	return (
		<Box component="header" sx={headerContainerUpdatedSX({isMobile})}>
			<Box sx={{
				width: '100%', position: 'relative', zIndex: 1, backgroundColor: `primary.main`,
			}}>
				< Stack
					direction="row"
					alignItems="center"
					textAlign="center"
					justifyContent="center"
				>
					<Typography variant='body3Bold' sx={{ color: `primary.contrastText`, marginTop: '5px' }}>{PunchOutLabels.TagLine.t()}</Typography>
				</Stack>
				<Container maxWidth="xl">
					< Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={2}
						py={2}
					>
						<Stack sx={{ maxWidth: { xs: 144, sm: 175 } }} direction="column" alignItems="center">
							{parse(logoData ? logoData : '')}
						</Stack>
						<Stack
							direction="row"
							alignItems="center"
							spacing={2}
							sx={{ display: { xs: 'none', sm: 'block' }, marginLeft: '150px !important' }}
						>
							<HeaderSearch />
						</Stack>
						<Stack
							direction="row"
							spacing='3'
							alignItems="center"
							justifyContent="flex-end"
						>

							{/* <Linkable
								href={`/home`}
								aria-label={'Quick Order'}
							>
								<Typography variant="body3Bold" sx={{ color: `primary.contrastText`, svg: { margin: '0 4px 0 0', fontSize: '14px' }, margin: '0 32px 0 0' }}><FontAwesomeIcon icon={faCalendar} fontSize='14px' /> {PunchOutLabels.QuickOrder.t()}</Typography>
							</Linkable> */}

							<HeaderMiniCart mobileBreakpoint="md" />
							<Button onClick={() => setOpen(true)} sx={{ margin: '0 0 0 32px !important', borderRadius: '4px', padding: '8px 16px', backgroundColor: `primary.contrastText`, border: `1px solid button.blue`, color: `button.blue`, ':hover': { backgroundColor: `primary.contrastText`, color: `button.blue` } }}>
								<Typography variant="body3Bold" sx={{ color: `button.blue`, svg: { margin: '0 4px 0 0', fontSize: '14px' } }}><FontAwesomeIcon icon={faArrowRightFromBracket} fontSize='14px' />{PunchOutLabels.CloseSession.t()}</Typography>
							</Button>
							<Modal
								open={open}
								onClose={() => setOpen(false)}
								aria-labelledby="modal-title"
								aria-describedby="modal-description"
							>
								<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
									<Box sx={{ backgroundColor: `secondary.contrastText`, padding: '40px', width: '100%', maxWidth: '860px', height: 'auto', position: 'absolute' }}>
										<Box sx={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
											<Typography variant="h3">{PunchOutLabels.CloseSessionModelTitle.t()}</Typography>
											<IconButton
												size="large"
												color="primary"
												onClick={() => setOpen(false)}
												sx={{ padding: '0', margin: '0', color: `secondary.lightgrey` }}
											>
												<CloseIcon />
											</IconButton>

										</Box>

										<Typography variant="body3Light">{PunchOutLabels.CloseSessionModelDesc.t()}</Typography>
										<Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', marginTop: '32px' }}>
											<Button href="/webapp/wcs/stores/servlet/ClosePunchoutSessionView" sx={{ margin: '0 16px 0 0 !important', borderRadius: '4px', padding: '8px 16px', backgroundColor: `primary.contrastText`, border: '1px solid #326E99', color: `button.blue`, ':hover': { backgroundColor: `primary.contrastText`, color: `button.blue` } }}>
												{PunchOutLabels.LeavePage.t()}
											</Button>
											<Button onClick={() => setOpen(false)} sx={{ borderRadius: '4px', padding: '8px 16px', backgroundColor: `button.blue`, border: `1px solid button.blue`, color: `primary.contrastText`, ':hover': { backgroundColor: `button.blue`, color: `primary.contrastText` } }}>
												{PunchOutLabels.StayOnPage.t()}
											</Button>
										</Box>
									</Box>
								</Box>
							</Modal>
						</Stack>
					</Stack>
				</Container>
			</Box>
		</Box >
	);
};
