/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { headerBreak } from '@/components/content/Header/styles/break';
import { SxProps } from '@mui/material';

export const headerSearchSX = ({ isMobile, isTablet }: { isMobile?: boolean, isTablet?: boolean }): SxProps => ({
	width: isMobile ? '100%' : isTablet ? '300px': '460px',
	display: headerBreak({ mobile: 'flex', desktop: 'flex' }),
	...(isMobile ? { mb: 0, mx: 0 } : {}),

	'.MuiOutlinedInput-root': {
		height: '38px',
		fontSize: isMobile ? '12px' : '16px',
		'.MuiOutlinedInput-input': {
			'&::-webkit-input-placeholder': {
				opacity: 0.8,
				fontSize: isMobile ? '12px' : '16px',
				lineHeight: '18px',
			},
		},
		'.MuiOutlinedInput-notchedOutline': {
			borderWidth: '1px',
			borderColor: 'secondary.lightgrey',
		},
	},
});
