import { Linkable } from '@/components/blocks/Linkable';
import { Box, Stack, Typography, MenuItem } from '@mui/material';
import { accountSignoutHeadingSX, accountSignoutMenuItemSX, accountSignoutMenuListTypographySX, accountSignoutMenuSX } from '@/components/content/Header/styles/SignOutDrawer';
import { useLocalization } from '@/data/Localization';
import { PAY_INVOICE_ROUTE } from '@/data/constants/account';
import { useLogout } from '@/data/Content/Logout';
import { FC, SyntheticEvent, useMemo } from 'react';
import { renderContent } from '@/utils/renderContent';
import { useContentRecommendation } from '@/data/Content/ContentRecommendation';
import { ProcessedContent } from '@/data/types/Marketing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRight } from '@fortawesome/sharp-light-svg-icons';


type Props = {
	setIsModalOpen: (value: boolean) => void;
};

export const AccountAfterSignInContent: FC<Props> = ({ setIsModalOpen }) => {
	const routes = useLocalization('Routes');
	const HeaderLabels = useLocalization('Header');
	const { handleLogout } = useLogout();
	const handleSignOutClick = (e: SyntheticEvent<Element, Event>) => {
		handleLogout(e);
		setIsModalOpen(false);
	};
	const handleCloseModal = () => {
		setIsModalOpen(false);
	};
	const dataMap = (contents: any) => contents?.at(0);
	const { data: contentHtml } = useContentRecommendation('global_header_myaccount_advantagelink');
	const myAdvantageContent: ProcessedContent | undefined = useMemo(() => dataMap(contentHtml), [contentHtml]);
	return (
		<Box>
			<Typography variant={'h3'} component={'h3'} sx={accountSignoutHeadingSX}>
				{HeaderLabels.Actions.Account.t()}
			</Typography>
			<Stack direction={'column'} sx={accountSignoutMenuSX}>
				<Linkable href={routes.Account.route.t()} onClick={() => handleCloseModal()}>
					<MenuItem sx={accountSignoutMenuItemSX} value="create">
						<Stack direction="row" alignItems="center" spacing={1}>
							<Typography sx={accountSignoutMenuListTypographySX}>
								{HeaderLabels.SignOutAccountPopper.AccountDashboard.t()}
							</Typography>
						</Stack>
					</MenuItem>
				</Linkable>
				<Linkable href={routes.OrderHistory.route.t()} onClick={() => handleCloseModal()}>
					<MenuItem sx={accountSignoutMenuItemSX} value="create">
						<Stack direction="row" alignItems="center" spacing={1}>
							<Typography sx={accountSignoutMenuListTypographySX}>
								{HeaderLabels.SignOutAccountPopper.OrderHistory.t()}
							</Typography>
						</Stack>
					</MenuItem>
				</Linkable>
				<Linkable href={routes.AddressBook.route.t()} onClick={() => handleCloseModal()}>
					<MenuItem sx={accountSignoutMenuItemSX} value="create">
						<Stack direction="row" alignItems="center" spacing={1}>
							<Typography sx={accountSignoutMenuListTypographySX}>
								{HeaderLabels.SignOutAccountPopper.AddressBook.t()}
							</Typography>
						</Stack>
					</MenuItem>
				</Linkable>
				<Linkable href={routes.AccountSettings.route.t()} onClick={() => handleCloseModal()}>
					<MenuItem sx={accountSignoutMenuItemSX} value="create">
						<Stack direction="row" alignItems="center" spacing={1}>
							<Typography sx={accountSignoutMenuListTypographySX}>
								{HeaderLabels.SignOutAccountPopper.AccountSettings.t()}
							</Typography>
						</Stack>
					</MenuItem>
				</Linkable>
				<a href={PAY_INVOICE_ROUTE} target='_blank' style={{ textDecoration: 'none' }}>
					<MenuItem sx={accountSignoutMenuItemSX} value="create" onClick={() => handleCloseModal()}>
						<Stack direction="row" alignItems="center" spacing={1}>
							<Typography display={'flex'} gap={'4px'} alignItems={'center'} sx={accountSignoutMenuListTypographySX}>
								{HeaderLabels.SignOutAccountPopper.PayInvoice.t()}
								<FontAwesomeIcon color="#656D73" size="sm" icon={faArrowUpRight} />
							</Typography>
						</Stack>
					</MenuItem>
				</a>
				<Linkable href={routes.QuickOrder.route.t()} onClick={() => handleCloseModal()}>
					<MenuItem sx={accountSignoutMenuItemSX} value="create">
						<Stack direction="row" alignItems="center" spacing={1}>
							<Typography sx={accountSignoutMenuListTypographySX}>
								{HeaderLabels.SignOutAccountPopper.QuickOrder.t()}
							</Typography>
						</Stack>
					</MenuItem>
				</Linkable>
				{myAdvantageContent ?
					(
						<MenuItem sx={accountSignoutMenuItemSX} value="create" onClick={() => handleCloseModal()}>
							<Stack direction="row" alignItems="center" spacing={1}>
								<Typography sx={accountSignoutMenuListTypographySX}>
									{renderContent(myAdvantageContent)}
								</Typography>
							</Stack>
						</MenuItem>
					) : null}
				<Linkable href="/" onClick={(e: SyntheticEvent<Element, Event>) => handleSignOutClick(e)}>
					<MenuItem sx={accountSignoutMenuItemSX} value="create">
						<Stack direction="row" alignItems="center" spacing={1}>
							<Typography sx={accountSignoutMenuListTypographySX}>
								{HeaderLabels.SignOutAccountPopper.SignOut.t()}
							</Typography>
						</Stack>
					</MenuItem>
				</Linkable>
			</Stack>
		</Box>
	);
};
