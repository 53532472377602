/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { headerNavBarDropMenuItemMobileSX } from '@/components/content/Header/styles/navBar/dropMenuItemMobile';
import { Button, Link, Typography, MenuItem } from '@mui/material';
import React, { FC } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useContentRecommendation } from '@/data/Content/ContentRecommendation';
import { PageLink } from '@/data/Navigation';
import { NAVIGATION_MARKETING_SPOT_SUFFIX, NAVIGATION_ADDITIONAL_LINKS_MARKETING_SPOT_SUFFIX } from '@/data/constants/marketing';
import { ProcessedContent } from '@/data/types/Marketing';


export const MobileMenuListItem: FC<{
  identifier?: string;
  activeLevel: number;
  updateDrawerData: (data: PageLink[], level: number, parent: PageLink, categoryEspotData: ProcessedContent[] | undefined, categoryAuxEspotData: ProcessedContent[] | undefined) => void,
  tree: PageLink[];
  label: string;
  url?: string;

}> = ({ identifier, activeLevel, tree, label, url, updateDrawerData }) => {
  const id = identifier + NAVIGATION_MARKETING_SPOT_SUFFIX;
  const suppId = identifier + NAVIGATION_ADDITIONAL_LINKS_MARKETING_SPOT_SUFFIX;
  const { data: categoryEspotData } = useContentRecommendation(id);
  const { data: categorySuppEspotData } = useContentRecommendation(suppId);

  return (
    <MenuItem key={label} sx={headerNavBarDropMenuItemMobileSX} className={activeLevel > 1 ? "jjk-mobile-nav-l2" : "jjk-mobile-nav-l1"}>
      {activeLevel <= 2 ?
        tree.length > 0 && activeLevel === 1 || categoryEspotData ?
          <Button
            disableRipple={true}
            onClick={() => updateDrawerData(tree, activeLevel + 1, { label, url, children: tree, identifier }, categoryEspotData, categorySuppEspotData)
            }
            component="a"
            sx={{ alignSelf: 'stretch' }}
          >
            <Typography variant="body2Bold"> {label}</Typography>
            {<ChevronRightIcon />}
          </Button> :
          <Link
            variant="body2Bold"
            href={url}
            sx={{ alignSelf: 'stretch' }}
          >
            {label}
          </Link>
        : <Link
          variant="body2Bold"
          href={url}
          sx={{ alignSelf: 'stretch' }}
        >
          {label}
        </Link>

      }
    </MenuItem>
  );
};


