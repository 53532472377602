import { SxProps, Theme } from '@mui/material';

export const pdpDriverContainerSX: SxProps<Theme> = (theme) => ({
    '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: '375px',
        padding: '24px 24px 0',
        backgroundColor: 'background.modal',
				[theme.breakpoints.down(376)]: {
					width: '100%',
				},
    },
	'.modal-close-btn': {
        position: 'absolute',
        top: '26px',
        right: '26px',
        cursor: 'pointer',
        '& svg': {
            color: 'icons.fillcolor',
        },
    },
    '& form': {
		margin: '0',
	},
    '& .field-wrapper': {
			width: '100%',
			marginLeft: '0',
			'& label': {
				fontSize: '16px',
				color: 'icons.fillcolor',
				backgroundColor: 'transparent',
				lineHeight: 'normal',
			},
			'& label.MuiInputLabel-shrink': {
				fontSize: '14px',
				backgroundColor: 'background.lightgray',
				padding: '1px 4px',
			},
			'& .MuiAutocomplete-inputRoot': {
				padding: '0',
			},
			'& .MuiInputBase-root': {
				backgroundColor: 'transparent',
				'& input': {
					padding: '14.5px 14px',
					fontSize: '16px',
					color: 'icons.fillcolor',
					height: 'auto',
				},
				'& fieldset': {
					border: '1px solid',
					borderColor: 'icons.fillcolorHover',
					borderRadius: '4px',
				},
			},
			'& .visibility-label': {
				'& > .MuiTypography-root': {
					color: 'heading.dark',
					fontSize: '14px',
				},
			},
			'& .MuiButtonBase-root.Mui-checked': {
				color: 'radio.burgundy',
			},
			'& .MuiFormControlLabel-label': {
				fontSize: '14px',
				fontWeight: '400',
				color: 'text.main',
			},
			'& .Mui-error': {
				color: 'text.error',

				'& fieldset': {
					border: '2px solid',
					borderColor: 'text.error',
				},
			},
		},
});

export const pdpDriverContainerButtonSX: SxProps = {
    position: 'absolute',
    top: '26px',
    right: '26px',
    cursor: 'pointer',
    '& svg': {
        color: 'icons.fillcolor',
    },
};
