/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { headerMobileDrawerSX } from '@/components/content/Header/styles/mobileDrawer';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useNavigation } from '@/data/Navigation';
import { Box, Drawer, Stack, Typography, Button, MenuList, MenuItem } from '@mui/material';
import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { PageLink } from '@/data/Navigation';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ContentItemProperties } from '@/data/types/Slot';
import { MobileMenuListItem } from '@/components/content/Header/parts/MobileMenuListItem';
import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { ProcessedContent } from '@/data/types/Marketing';
import { renderContent } from '@/utils/renderContent';
import { useContentEvents } from '@/data/Content/_ContentEvents';
import { headerNavBarDropMenuItemMobileSX } from '@/components/content/Header/styles/navBar/dropMenuItemMobile';


const MAX_LEVELS = 2;
export const HeaderMobileDrawer: FC<{
	toggleDrawer: (open?: boolean) => () => void;
	open: boolean;
	mobileNavEspotData?: ContentItemProperties | undefined
}> = ({ toggleDrawer, open, mobileNavEspotData }) => {
	const { navigation } = useNavigation();
	const router = useNextRouter();
	const { onContentClick } = useContentEvents();
	const [activeTree, setActiveTree] = React.useState<{ parent: PageLink | null, tree: PageLink[] }>({ parent: null, tree: navigation?.filter(({ url }) => !!url) as PageLink[] });
	const [treeHistory, setTreeHistory] = React.useState<{ parent: PageLink | null, tree: PageLink[] }[]>([]);
	const [activeLevel, setActiveLevel] = React.useState<number>(1);
	const [categoryEspotData, setCategoryEspotData] = React.useState<ProcessedContent[] | undefined>(undefined);
	const [categorySuppEspotData, setCategorySuppEspotData] = React.useState<ProcessedContent[] | undefined>(undefined);

	const updateDrawerData = React.useCallback(
		(data: PageLink[], level: number, parent: PageLink | null, categoryEspotData: ProcessedContent[] | undefined, categorySuppEspotData: ProcessedContent[] | undefined) => {
			if (activeLevel <= MAX_LEVELS) {
				const appendedHistory = [...treeHistory, { parent: activeTree.parent, tree: activeTree.tree }];
				setActiveTree({ parent, tree: data });
				setTreeHistory(appendedHistory);
				setCategoryEspotData(categoryEspotData);
				if (activeLevel === 1 && categorySuppEspotData) {
					setCategorySuppEspotData(categorySuppEspotData);
				} else {
					setCategorySuppEspotData(undefined);
				}
			}

			setActiveLevel(level);
		}, [activeLevel, activeTree, treeHistory]
	);
	const goPrevTree = React.useCallback(() => {
		const level = activeLevel - 1;
		const newTree = treeHistory[level - 1];
		treeHistory.pop();
		setTreeHistory(treeHistory);
		setActiveLevel(level);
		setActiveTree({ parent: newTree.parent, tree: newTree.tree });
		setCategoryEspotData(undefined);
	}, [activeLevel, treeHistory]
	);

	React.useEffect(() => {
		if (!router.asPath) return;
		toggleDrawer(false)();
	}, [router.asPath, toggleDrawer]);

	const closeDrawer = React.useCallback(
		() => {
			toggleDrawer(false)();
			setTreeHistory([]);
			setActiveLevel(1);
			setActiveTree({ parent: null, tree: navigation?.filter(({ url }) => !!url) as PageLink[] });
		}, [navigation, toggleDrawer]
	);
	return (
		<Drawer anchor="left" open={open} className="jjk-mobile-nav">
			<Box sx={headerMobileDrawerSX} role="presentation">
				<Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
					<Stack direction="row" alignItems="center">
						<Button sx={{ padding: 0, marginRight: '.5rem', display: activeLevel === 1 ? 'none': 'inline-flex' }} onClick={() => goPrevTree()} disabled={activeLevel === 1}>
							<ChevronLeftIcon />
						</Button>
						<Typography variant="body3Bold" sx={{marginLeft: activeLevel === 1 ? '5px': '0'}}>{activeTree.parent ? activeTree.parent?.label : 'Menu'}</Typography>
					</Stack>
					<CloseIcon onClick={() => closeDrawer()} sx={{ cursor: 'pointer' }} />
				</Stack>
				<Stack direction="column">
					{navigation ?
						<Stack direction="column">

							{activeTree && activeTree?.tree?.length && activeLevel <= MAX_LEVELS ? (
								<MenuList> {activeTree?.tree?.filter(({ identifier }) => !identifier?.startsWith('_')).map(({ label, url, children, identifier }) => (
									<MobileMenuListItem key={label} identifier={identifier} updateDrawerData={updateDrawerData} activeLevel={activeLevel} tree={children} label={label} url={url} />
								))}
									{categorySuppEspotData && activeLevel !== 1 ? (<MenuItem sx={headerNavBarDropMenuItemMobileSX}>{categorySuppEspotData?.map((content) => renderContent(content, onContentClick(content)))}</MenuItem>) : null}
								</MenuList>

							) : (<Stack direction="column" sx={{ paddingTop: 3 }}>{categoryEspotData?.map((content) => renderContent(content, onContentClick(content)))}</Stack>)
							}

						</Stack> : null
					}
					{mobileNavEspotData && activeLevel === 1 ?
						<Stack direction="column" sx={{ borderTop: '1px solid', borderColor: 'border.gray', paddingTop: 3 }}>

							<ContentRecommendation
								id={`${mobileNavEspotData?.emsName}`}
								properties={mobileNavEspotData}
							/>
						</Stack> : null}

				</Stack>
			</Box>
		</Drawer>
	);
};
