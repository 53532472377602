import { headerBreak } from '@/components/content/Header/styles/break';
import { SxProps } from '@mui/material';

export const advantageSubHeaderSX: SxProps = {
	backgroundColor: 'background.paper',
	borderRadius: 0,
	top: headerBreak({ mobile: '125px', desktop: '200px' }),
	zIndex: headerBreak({ mobile: '2', desktop: '1' }),
	position: headerBreak({ mobile: 'sticky', desktop: 'sticky' }),
	overflow: 'visible',
	whiteSpace: 'nowrap',
	'& .advantage-list': {
		borderBottom: '3px solid',
		borderColor: 'transparent',
		padding: '0 16px',
		'& a': {
			padding: '16px 0 13px',
		},
		'&:hover': {
			borderColor: '#DC8538',
		},
	},
	'.MuiLink-root:hover': {
		textDecoration: 'none',
	},
	'& .adv-active-link': {
		borderColor: '#DC8538',
	},
};
