/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useMediaQuery, useTheme } from '@mui/material';
import React, { FC } from 'react';

export const HeaderMobileToggleButton: FC<{
	toggleDrawer: (open?: boolean) => () => void;
	open: boolean;
}> = ({ toggleDrawer }) => {
	const theme = useTheme();
	const show = useMediaQuery(theme.breakpoints.down('md'));
	return show ? (
		// eslint-disable-next-line react/forbid-component-props
		<FontAwesomeIcon icon={faBars} style={{color: '#8d4960', width: '24px', height: '24px'}} onClick={toggleDrawer()} />
	) : null;
};
